<template>
    <div class="listener" id="toast-event-listener"></div>
</template>

<script>
import { ref, computed, defineComponent } from '@vue/runtime-core'
import { useVueSweetAlert2 } from '@/lib/useVueSweetAlert2'
import { useStore } from 'vuex'

export default defineComponent({
    setup() {
        const $swal = useVueSweetAlert2()
        const store = useStore()
        const isAlertCurrentlyShown = ref(false)
        const eventSource = computed(() => {
            return new EventSource(
                `https://${process.env.VUE_APP_URL}/backend/api/game/sse/toasts.php?uuid=${store.state.game.currentGame.uuid}`,
                // `https://${window.location.hostname}/backend/api/sse/toaster.php`,
                { withCredentials: false }
            )
        })

        eventSource.value.onmessage = (event) => {
            let json = JSON.parse(event.data)
            if (!json.alert) return
            if (isAlertCurrentlyShown.value) return
            isAlertCurrentlyShown.value = true
            sweetAlert(json.alert)
            return false
        }

        const sweetAlert = async (toastObject) => {
            const toastConfirmation = await $swal.fire(toastObject)
            useApi('toastSeen', {
                uuid: store.state.game.currentGame.uuid,
                logId: toastObject.id,
            })
            // fetch(
            //     `https://${process.env.VUE_APP_URL}/backend/api/game/toast/toastSeen.php?uuid=${store.state.game.currentGame.uuid}&log_id=${toastObject.id}`
            // ).then((res) => res.json())
            isAlertCurrentlyShown.value = false
        }
    },
})
</script>

<style></style>
