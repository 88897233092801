export const useApi = async (api, params) => {
    switch (api) {
        case 'syncPlayer':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/syncPlayer.php?uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'desyncPlayer': 
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/desyncPlayer.php?uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'isPlayer':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/isPlayer.php?access_code=${params.code}&email=${params.email}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'loadPlayerdata':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/loadPlayerdata.php?uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'newPlayer':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/newPlayer.php?email=${params.email}&access_code=${params.code}&display_name=${params.username}&agb=${params.agb}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'addItem':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/addItem.php?uuid=${params.uuid}&name=${params.name}&img=${params.img}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'loadInventory':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/loadInventory.php?uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'getReward': 
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/getReward.php?uuid=${params.uuid}&current_page=${params.currentPage}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'getNextPage':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/getNextPage.php?current_path=${params.currentPath}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'playerUpdateCurrentPage':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/updateCurrentPage.php?uuid=${params.uuid}&next_page=${params.page}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'playerTimePlayed':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/updateTimePlayed.php?uuid=${params.uuid}&time_played=${params.timePlayed}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'playerTimeAdded':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/updateTimeAdded.php?uuid=${params.uuid}&time_added=${params.timeAdded}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'playerGameState':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/player/updateGameState.php?uuid=${params.uuid}&game_state=${params.gameState}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'wasHintUsed':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/hint/wasHintUsed.php?hint=${params.hint}&uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'useHint':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/hint/useHint.php?hint=${params.hint}&uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'getHints':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/hint/getHints.php?current_page=${params.currentPage}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'newTeam':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/team/newTeam.php?code=${params.code}&display_name=${params.teamName}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'teamUpdateCurrentPage':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/team/updateCurrentPage.php?uuid=${params.uuid}&next_page=${params.page}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'isCodeAlreadyUsed':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/code/isCodeAlreadyUsed.php?access_code=${params.code}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'isAccessValid':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/code/isAccessValid.php?access_code=${params.code}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'solveRiddle':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/riddle/solveRiddle.php?current_page=${params.currentPage}&uuid=${params.uuid}&riddle_answer=${params.answer}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'forceSolveRiddle':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/riddle/forceSolveRiddle.php?current_page=${params.currentPage}&uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'alertSeen':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/alert/alertSeen.php?uuid=${params.uuid}&log_id=${params.logId}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'toastSeen':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/toast/toastSeen.php?uuid=${params.uuid}&log_id=${params.logId}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'keepAlive':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/game/timer/keepAlive.php?uuid=${params.uuid}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'getBewertungen':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/rate/getBewertungen.php`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'getMarkedBewertungen':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/rate/getMarkedBewertungen.php`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'createBewertung':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/rate/createBewertungen.php?userName=${params.userName}&stars=${params.stars}&text=${params.text}&playTime=${params.playTime}`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
        case 'getBewertungenStars':
            return fetch(`https://${process.env.VUE_APP_URL}/backend/api/rate/getBewertungenStars.php`).then((res) => {
                const json = res.json()
                // console.log(json)
                return json
            })
    }
}
