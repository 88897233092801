<template>
    <!-- <div class="inventory-item"> -->
    <img
        @click="openPopup"
        class="inventory-img"
        :src="props.item.img"
        alt=""
    />
    <!-- </div> -->
</template>

<script>
import { defineComponent, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useEmitter } from '../../lib/useEmitter'

export default defineComponent({
    props: {
        item: {
            required: true,
        },
    },
    setup(props) {
        const popupClassList = ref('popup hidden')
        const store = useStore()
        const emitter = useEmitter()
        // const openPopup = () => (popupClassList.value = 'popup')
        const openPopup = () => {
            if (props?.item?.emitter) emitter.emit(props.item.emitter)
            store.commit({
                type: 'addPopup',
                imgSrc: props.item.img, 
                componentType: 'Popup'
            })
        }
        const closePopup = () => (popupClassList.value = 'popup hidden')
        return { props, popupClassList, closePopup, openPopup }
    },
})
</script>

<style scoped>
.inventory-item {
    /* justify-self: center; */
}
.inventory-img {
    justify-self: center;
    cursor: pointer;
    border: 1px solid var(--base-white);
    width: 70%;
    padding: 5px;
    border-radius: 7px;
}
</style>
