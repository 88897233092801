/** @format */

import { createStore } from 'vuex'

import shop from '@/store/modules/shop'
import game from '@/store/modules/game'
import general from '@/store/modules/general'

const store = createStore({
    modules: {
        general: general,
        shop: shop,
        game: game,
    },
})

export default store
