<template>
    <button class="music-btn" @click="mute" v-show="playing">
        <font-awesome-icon icon="volume-high" />
    </button>
    <button class="music-btn" @click="unmute" v-show="!playing">
        <font-awesome-icon icon="volume-xmark" />
    </button>
</template>

<script setup>
import { ref, onMounted } from '@vue/runtime-core'
import * as backgroundSounds from '@/assets/sound_files/backgroundSounds.mp3'

const playing = ref(false)
const audio = new Audio()

audio.src = backgroundSounds
audio.addEventListener(
    'ended',
    function () {
        //console.log(this)
        this.currentTime = 5
        this.play()
    },
    false
)

const mute = () => {
    playing.value = false
    audio.pause()
}

const unmute = () => {
    playing.value = true
    audio.play()
}
</script>

<style scoped>
.music-btn {
    position: absolute;
    top: 0;
    left: 80px;
    z-index: 4;
}
.music-btn {
    border: 1px solid var(--base-black);
    border-radius: 7px;
    background: var(--base-white);
    text-align: center;
    color: var(--base-black);
    cursor: pointer;
    line-height: 40px;
    height: 40px;
    width: 40px;
    padding: 0;
}
</style>
