<template>
    <header>
        <div class="feed"></div>
    </header>
</template>

<script>
export default {}
</script>

<style scoped>
.feed {
    height: 30px;
    background-color: var(--main-bg-color);
    padding-left: 100px;
    padding-right: 100px;
}
</style>
