<script setup>
import {usePusher} from "@/hooks/usePusher";
import {onMounted, onUnmounted} from "vue";
import { useVueSweetAlert2 } from '@/lib/useVueSweetAlert2'
import {useChangePage} from '@/lib/useChangePage'
import {useStore} from "vuex"
import {useI18n} from 'vue-i18n'

const pusher = usePusher();

const props = defineProps({
    apiKey: {
        required: true,
        type: String
    }
})

const store = useStore()
const { t } = useI18n({ useScope: 'global' });
const $swal = useVueSweetAlert2()

const pusherEvents = [
    "test-event",
    "test-event2",
    "team-changed-page",
    "team-alert",
]

const handleEvent = (pusherEvent, data) => {
    console.log(pusherEvent, data)
    if(!store.state.game.currentGame.isSyncedWithTeam) return;
    const payload = {}
    switch(pusherEvent) {
        case "team-changed-page":
            console.log(data)
            // store.commit('updatePage', data)
            useChangePage({
                method: 'page',
                path: data['url'],
            })
            break;
        /*
        case "team-current-state":
            payload.gameState = data.gameState
            store.commit('updateGameState', payload)
            break;
         */
        case "team-time-added":
            payload.timeAdded = data.timeAdded
            store.commit('updateTimeAdded', payload)
            break;
        case "team-alert":
            console.log(data)
            // Guards damit das Popup nicht auf den Read only seiten erscheint
            if(window.location == `https://${process.env.VUE_APP_URL}/game/tutorial`) return
            if(window.location == `https://${process.env.VUE_APP_URL}/game/schloss/fragment`) return
            if(window.location == `https://${process.env.VUE_APP_URL}/game/kirche/fragment`) return
            if(window.location == `https://${process.env.VUE_APP_URL}/game/obelisk/fragment`) return
            if(data.actionType == "PlayerSolvedRiddle") return $swal.fire({toast: true, icon: 'success', title: t("Utilities.Alerts.PlayerSolvedRiddle.Title"),html: t("Utilities.Alerts.PlayerSolvedRiddle.Body"), timer: 3000, showConfirmButton: false})
        default:
            console.log(data)
    }
}

onMounted(() => {
    const channel = pusher.subscribe(`${props.apiKey}-channel`)
    pusherEvents.forEach(pusherEvent => channel.bind(pusherEvent, data => handleEvent(pusherEvent, data)))
})

onUnmounted(() => {
    pusher.unsubscribe(`${props.apiKey}-channel`)
})
</script>
