<template>
    <div class="popup">
        <div class="popup-close-button" @click="closePopup">X</div>
        <v-zoomer class="zoomer">
            <img class="zoomer-image" :src="imgSrc" alt="Popup"/>
        </v-zoomer>
    </div>
</template>

<script>
import InnerImageZoom from 'vue-inner-image-zoom'

export default {
    components: {
        'inner-image-zoom': InnerImageZoom,
    },
    props: {
        imgSrc: {
            type: String,
            required: true,
        },
        isForced: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        closePopup() {
            this.$emit('removePopup')
            this.$store.commit({
                type: 'removePopup',
                imgSrc: this.imgSrc,
            })
            if (this.isForced) {
                this.emitter.emit('forceSolve')
            }
        },
    },
}
</script>

<style scoped>
.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
.popup {
    position: relative;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2;
    height: 100vh;
    width: 100%;
}
.zoomer {
    width: 50vw;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
}
.zoomer-image {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
</style>
