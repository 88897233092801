<template>
    <div class="main">
        <p>
            {{ $t('Components.Game.Timer.Text') }}:
            {{ this.formattedElapsedTime }}
        </p>
    </div>
</template>
<script>
import {useStore} from 'vuex'

export default {
    data() {
        return {
            start: 0,
            timer: undefined,
        }
    },
    computed: {
        playertime() {
            return (
                parseInt(this.$store.state.game.currentGame.timePlayed) +
                parseInt(this.$store.state.game.currentGame.timeAdded)
            )
        },
        formattedElapsedTime() {
            const date = new Date(null)
            const store = useStore()

            date.setSeconds(parseInt(this.playertime) + parseInt(this.start))

            store.commit({ type: `updateTimePlayed`, timePlayed: this.start })

            const utc = date.toUTCString()
            // return utc.substr(utc.indexOf(':') - 2, 8)
            return utc.slice(-12, -3)
        },
        checkRiddleSolved() {
            const store = this.$store.getters.allRiddlesSolved()
            return store
        },
    },
    beforeMount() {
        this.timer = setInterval(() => {
            this.start += 1
        }, 1000)
    },
    beforeUpdate() {
        if (this.checkRiddleSolved) {
            clearInterval(this.timer)
        }
    },
}
</script>
<style scoped>
h3 {
    margin: 0;
}
p {
    font-size: 16px;
    font-family: Special Elite;
    margin: 0;
}
.main {
    flex-basis: auto;
    margin-right: 20px;
}
</style>
