<template>
    <div
        v-if="!store.state.game.currentGame.disableRiddleInput"
        class="riddle-input"
    >
        <label for="riddle-answer">{{
            $t('Components.Game.RiddleInput.Label')
        }}</label>
        <input
            id="riddle-answer"
            v-model="answer"
            type="text"
            :disabled="disabled"
        />
        <button @click="solveRiddle" :disabled="disabled">
            {{ $t('Components.Game.RiddleInput.Button') }}
        </button>
    </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/runtime-core'
import { useVueSweetAlert2 } from '@/lib/useVueSweetAlert2.js'
import { useChangePage } from '@/lib/useChangePage.js'
import { useStore } from 'vuex'
import { useApi } from '@/lib/useApi'
import confetti from "canvas-confetti";

export default defineComponent({
    setup() {
        const store = useStore()
        const answer = ref('')

        const disabled = computed(() => {
            return store.state.game.currentGame.disableRiddleInput == true ||
                window.location.pathname == `/game/map` ||
                window.location.pathname.indexOf('fragment') > -1
                ? true
                : false
        })
        const $swal = useVueSweetAlert2()

        function explosion() {
            var count = 200;
            var defaults = {
            origin: { y: 0.7 },
            zIndex: 2000
            };

            function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
            }

            fire(0.25, {
            spread: 26,
            startVelocity: 55,
            });
            fire(0.2, {
            spread: 60,
            });
            fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
            });
            fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
            });
            fire(0.1, {
            spread: 120,
            startVelocity: 45,
            })
        }

        async function solveRiddle() {
            /* prettier-ignore */
            let solved = await useApi('solveRiddle', {
                uuid: store.state.game.currentGame.uuid,
                answer: answer.value,
                currentPage: window.location.pathname,
            })
            console.log(store.state.game.currentGame.timePlayed)
            useApi('playerTimePlayed', {
                uuid: store.state.game.currentGame.uuid,
                timePlayed: store.state.game.currentGame.timePlayed
            })

            if (solved === true) {
                let item = await useApi('getReward', {
                    uuid: store.state.game.currentGame.uuid,
                    currentPage: window.location.pathname,
                })

                /* prettier-ignore */
                if (item) useApi('addItem', {
                    uuid: store.state.game.currentGame.uuid,
                    name: item.name,
                    img: item.img,
                })

                // Return Firework
                explosion()

                return $swal
                    .fire({
                        title: this.$t(
                            'Utilities.Alerts.PlayerSolvedRiddle.Title'
                        ),
                        icon: 'success',
                        confirmButtonText: this.$t(
                            'Utilities.Alerts.PlayerSolvedRiddle.Button'
                        ),
                        allowOutsideClick: 'false',
                    })
                    .then((res) => {
                        if (!res.isConfirmed) return
                        answer.value = ''
                        useChangePage({ method: 'nextRiddle' })
                    })
            }
            solved.html = eval(solved.html)

            $swal.fire(solved)
        }

        return { store, answer, disabled, solveRiddle }
    },
    mounted() {
        var vm = this
        async function forceSolve() {
            useApi('forceSolveRiddle', {
                uuid: vm.$store.state.game.currentGame.uuid,
                currentPage: window.location.pathname,
            }).then(async function () {
                return vm.$swal
                    .fire({
                        title: vm.$t(
                            'Utilities.Alerts.PlayerSolvedRiddle.Title'
                        ),
                        icon: 'success',
                        confirmButtonText: vm.$t(
                            'Utilities.Alerts.PlayerSolvedRiddle.Button'
                        ),
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        closeOnClickOutside: false,
                    })
                    .then((res) => {
                        if (!res.isConfirmed) return

                        useChangePage({ method: 'nextRiddle' })
                    })
            })
        }
        this.emitter.on('forceSolve', forceSolve)
    },
})
</script>

<style scoped>
.riddle-input {
    display: flex;
    gap: 5px;
    align-items: center;
}
label {
    font-size: 26px;
}
input {
    height: 30px;
}
button {
    height: 36px;
}
</style>
