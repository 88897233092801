export default {
  "Utilities": {
    "Alerts": {
      "GamePaused": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game is paused"])}
      },
      "GameResumed": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game will now resume"])}
      },
      "GameFinished": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game has been completed"])}
      },
      "PlayerSolvedRiddle": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The riddle's solution"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A player has solved the riddle. Do you want to take the result and continue playing with your team?"])},
        "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the next puzzle"])}
      },
      "TeamChangedPage": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your team is progressing"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your team has left the current page, do you want to go along and continue playing as a team or do you want to continue playing alone?"])}
      },
      "Hint": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Hint"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A player has had a hint displayed. Do you want to display this one too?"])}
      },
      "ConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "DenyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    },
    "Toasts": {
      "AnswerRightButNotFinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are on the right track"])},
      "AnswerWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not right"])},
      "TeamChangedPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your team is moving forward"])},
      "EmailNotCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email addresses do not match"])}
    },
    "Riddles": {
      "DescriptionReadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the puzzle"])}
    },
    "Fragments": {
      "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrap and continue"])}
    }
  },
  "Components": {
    "Game": {
      "Kirche": {
        
      },
      "Schloss": {
        "KugelGif": {
          
        },
        "SchiebeTafel": {
          
        },
        "Soundboard": {
          
        },
        "WappenRiddle": {
          
        }
      },
      "AlertEventListener": {
        
      },
      "DisableInput": {
        
      },
      "GameEventListener": {
        
      },
      "GameFooter": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["®Mystery Games GmbH"])}
      },
      "GameHeader": {
        
      },
      "Hint": {
        "HintValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes penalty"])},
        "ShowHint": {
          "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show hint"])},
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show solution"])}
        },
        "ConfirmUse": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want ", _interpolate(_named("timeValue")), " minutes penalty for the hint?"])}
      },
      "HintWrapper": {
        
      },
      "Inventory": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory"])}
      },
      "InventoryButton": {
        
      },
      "InventoryItem": {
        
      },
      "JigsawPopup": {
        
      },
      "PlayerVerifier": {
        
      },
      "Popup": {
        
      },
      "PopupContainer": {
        
      },
      "RiddleDescriptionSound": {
        
      },
      "RiddleDescriptionVideo": {
        
      },
      "RiddleInput": {
        "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
        "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solve"])}
      },
      "SwitchButton": {
        
      },
      "SyncButton": {
        "IsSynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You play with your team"])},
        "NotSynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You play alone"])}
      },
      "Timer": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elapsed time"])}
      },
      "ToastEventListener": {
        
      }
    },
    "Shop": {
      "InfoSection": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Escape Room"])},
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can play our online escape game directly in your browser at home and don't have to download an app or print game material. The puzzles can be solved alone or with as many other players as you like. Even if your game partners are physically separated, you can still play together online from anywhere with the same access code. What are you waiting for? Grab a few friends and let the puzzle fun begin. But don't worry, if you get stuck, there are hints for each puzzle."])},
        "FeatureTitles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.99€ per group"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 6 devices"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.5 hours on average"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without game material"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community game"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to start immediately"])},
          
        ],
        "FeatureTexts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exciting puzzle fun with lovely details, varied puzzles and professional story reader."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay once as a group and play with as many players as you like at the same time from different locations with up to 6 devices."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play time varies depending on skill and group size. No time limit! Recommended for ages 14 and up."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet-enabled device sufficient (PC, laptop or tablet is best), as well as notepad & pen."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect by phone or (video) chats e.g. Skype, WhatsApp or Zoom. Also suitable as a team event with our team mode!"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play immediately after paying. Access code is valid for an unlimited time and entitles you to play once."])},
          
        ]
      },
      "Item": {
        "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
        "Gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the game a present?"])}
      },
      "ItemPrice": {
        
      },
      "Nav": {
        "HomeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "LoginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
      },
      "ProductSection": {
        "Titles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play The Secret of Sittensen Castle online now"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Bewertungen*"])},
          
        ],
        "Texts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a collector approaches you with an offer to buy your old family painting, you set out to find your ancestor who was in the Schulten's service. Was the painting really painted by a sister of the Schults? How did the painting come into your possession? Can you solve the mystery of Sittensen Castle?"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a discount voucher ? Here you can redeem it."])},
          
        ]
      },
      "ShopFooter": {
        "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
        "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
        "ToS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T&C"])},
        "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
      },
      "ShopHeader": {
        
      },
      "StartSlider": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The secret of Sittensen Castle"])}
      }
    },
    "DebugBar": {
      
    },
    "LanguageChanger": {
      
    }
  },
  "Views": {
    "Game": {
      "Kirche": {
        "Altar": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["God created the world in six days, all good things come in threes even if the church speaks here rather of the Trinity, the week has seven days, whereby the seven also plays an important role in the church: 7 vices, 7 virtues and 7 last words on the cross. Again we gain the knowledge, everything in the universe is mathematics and as so often the solution of the riddle lies in the hidden. The third riddle in St. Dionysius Church therefore once again demands your full attention, a sharp eye and absolute concentration. Alea iacta est."])}
        },
        "AltarRueckseite": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And once again, the solution to the riddle lies hidden. In the search for the truth, it is often important to simply change one's perspective. In fact, the ancient Romans already knew that."])}
        },
        "Aposteldenkmal": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mathematics has paved your way into the church and mathematics will also accompany you on a heavenly symphony through the venerable St. Dionysius Church. Whether you believe or not is not important, but what counts here is the Word of God. Therefore, do as the Bible-believing brethren do and praise the Lord, and He will surely show you the right way."])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description Fragment"])}
        },
        "Steintafel": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have chosen the right path. But unfortunately the door of the church is locked. Is there perhaps another entrance or even a secret door or hidden clues? Somehow it must be possible to get into St. Dionysius Church! With logic, the right tools in your luggage and a sharp eye you will find the solution and always remember, in the end everything is somehow always mathematics and even the church speaks of divine signs and numbers. "])}
        },
        "Taufbecken": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For many people, churches are places of rest and power; adorned with divine and sometimes mysterious symbols and images of saints. In earlier times, however, churches were also supposed to present wealth and power. Not infrequently, history and destiny were directed through the church. What meaning does a church have for you? Is it a place of encounter? Does it offer comfort? Does it represent birth, life, and death? What story or even myth do the images and symbols tell us, and what power and perhaps even fascination do they exert on us? Does a church pursue ideals such as freedom, equality, fraternity, tolerance, and humanity? With an ethical and philosophical approach, perhaps this divine code can also be cracked."])}
        },
        "Wegweiser": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is cool and quiet in the church park of the old St. Dionysius Church. The leaves of the trees cast shadows on the path, everything seems peaceful. But what has happened here? Wasn't this where we were supposed to be shown the way? Apparently someone is trying to stop us from finding the clues - or can you put the clues back together? "])}
        },
        "Einleitung": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortunately, our ancestors kept a diary, which happened to fall into our hands. Thanks to the historical and personal lines we get an insight into the life and work. So we also learn, one of the stewards' tasks was to take care of the maintenance of the church and here especially the decoration and care of the historic altar, with the image of the four evangelists, cartilage decorated columns and golden angels. But there is more to discover in the sacred halls!"])}
        }
      },
      "Obelisk": {
        "Einleitung": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A look into the old diary of the caretaker family provides information about life on the farm, but also in particular about the duties and various tasks of our ancestors. Thus we learn, at least once a month the way led also to the garden care at the hereditary burial place. Under high deciduous trees, between meadows and fields and an idyllic babbling brook, the former lords found their last resting place, under heavy stone slabs and a towering enigmatic obelisk. Among them, perhaps, some mysteries were buried."])}
        },
        "AlexanderSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the right strategy you solve the mystery of the obelisk. The solution is provided by the royal game on the tombs. Can you ride the right way over the tombs with the help of the story of the Escaped Horse?"])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description Fragment"])}
        },
        "Obelisk": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mystery of the obelisk is not yet completely solved. Once again we realize mathematics solves many mysteries and as so often sacred numbers and in this case even an ancient Greek philosopher play an important role."])}
        },
        "OttoSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cubit, foot, stone and hammer throw, or inch and span, described distance and size in earlier times. But how big is the text in relation to the unit of measurement?"])}
        },
        "SophieSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scattered in all directions, notes are often the key to success. You should definitely keep that in mind if you want to solve the mystery of Sophie's grave."])}
        }
      },
      "Schloss": {
        "Einleitung": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know from stories and historical documents that the former stewards of the castle always lived on the castle grounds. In contrast to the noble owners, who were present on the premises only temporarily, e.g. for illustrious hunting excursions or in the entertaining summer retreat. The family of our ancestors was responsible for keeping the castle, park, lands, stables and equipment in good condition. Particular attention had to be paid to the castle. Even today it is said to hide many secrets behind its walls. "])}
        },
        "Adler": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once this place told of knights and princes of splendid gatherings and noble lords of the manor. In the manor park, statues and busts remind us of this bygone era, of strength as well as power, and they invite you to just take a closer look."])}
        },
        "Eingang": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this place, half the puzzle comes together as a whole and the ancient universe of the gods around war, love, domination, trade, heaven, water and earth begins to turn. But who goes behind whom? Many secrets are often kept in books."])}
        },
        "Eiskeller": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are now going to an underground, cold, empty and almost forgotten place. The cold makes you shiver or is it once again the secrets that make you shiver. Is the room really empty or can you just not see it? It's worth looking twice here, too, although others could feel this puzzle blindly, and then just let brute force take over, so that this half of the puzzle is solved."])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description Fragment"])}
        },
        "Galerie": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manor has opened its doors to you. You roam the rooms in search of stories and clues. Make sure you keep track of everything and don't get lost in the stories, imaginative pictures or even in the rooms, like a lost prince. Can you solve the mystery of the lost prince and help him and, in turn, yourself?"])}
        },
        "Kaminzimmer": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where once the flames provided warmth, today riddles are hiding. Sounds and signs point the right way and help you find the solution. So you can put your hand and see what is hidden from the eyes."])}
        },
        "Treppe": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries, cities, villages, powerful families and influential noble houses show their togetherness and strength colorfully, richly symbolic, full of pride now and then animalistic and often encoded. The knight's estate in Burgsittensen also once had such a distinguishing feature and a motto that is probably questionable from today's point of view. All you have to do: spread your wings and decode the symbolism in the right place."])}
        }
      },
      "Finished": {
        "Descriptions": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hooray, you solved all the riddles and found the letter as a certificate of authenticity. What do you want to do now?"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By having the painting confirmed as genuine, you have the opportunity to buy it."])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the painting has been in the family for a long time, you don't want to sell it."])},
          
        ],
        "Texts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Verkaufen"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Behalten"])},
          
        ],
        "FinishButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel Beenden"])}
      },
      "Karte": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many stories and adventures begin with the sentence “once upon a time“ or “it happened a very long time ago“. We would also like to start our joint adventure with one of these sentences."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...for it happened a very long time ago that one of our ancestors held the honorable office of farm steward on the monastery estate in Burgsittensen. Out of sheer gratitude for his many years of loyalty, tireless dedication and always correct performance of his duties, the then landowner and artist Auguste von Schulte (she lived from 1800 to 1856) gave him a portrait. This is the story that has been handed down from one generation to another. This is exactly what we have to prove now, because unfortunately Auguste did not sign many of her paintings. The portrait in question is now in your possession, along with the mysterious diary of your ancestor. A well-known art collector would like to buy the picture for a high sum, if the picture was really painted by Auguste von der Schulte. "])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Will you succeed in proving the authenticity of the portrait? Embark on an exciting search for clues at the former workplaces of Augustus and your ancestor - the former court administrator."])}
      },
      "Tutorial": {
        
      }
    },
    "Shop": {
      "Standorte": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our locations: "])}
      },
      "AGB": {
        "Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and agree to the "])},
        "Terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". I am aware that with registration I lose my right of withdrawal."])}
      },
      "Bewerten": {
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playtime"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rating"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playtime"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
          
        ],
        "Headlines": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
          
        ]
      },
      "Datenschutz": {
        "P": [
          
        ]
      },
      "Empty": {
        
      },
      "Home": {
        
      },
      "Impressum": {
        
      },
      "Kontakt": {
        "Headlines": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
          
        ],
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have an idea yet?"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage Senden"])},
          
        ],
        "Descriptions": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you interested in having your own online escape room? Then why not leave us your contact details. We will get back to you."])},
          
        ]
      },
      "Login": {
        "Titles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
          
        ],
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Code"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat E-mail"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
          
        ],
        "Toasts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not correct"])},
          
        ],
        "Alerts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please specify a team name"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
          
        ],
        "Errors": {
          "E-Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The emails do not match."])},
          "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a username to start"])},
          "AGB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept the terms and conditions."])},
          "inValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access code invalid."])},
          "Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please specify team name"])}
        }
      },
      "ZugangsCode": {
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal ID"])},
          
        ],
        "Headlines": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code not received?"])},
          
        ],
        "Descriptions": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your PayPal ID here and get your code again!"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your access code is"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code received"])},
          
        ]
      }
    },
    "App": {
      "Handy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do not use a cell phone or tablet. Some puzzles can not be solved."])}
    }
  }
}