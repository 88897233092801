/** @format */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@/style/game.css'
import i18n from './i18n'
import VueZoomer from 'vue-zoomer'
import mitt from 'mitt'
import Pusher from "./plugins/pusher"

/**
 * Font Awesome Icons
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faBook,
    faBookOpen,
    faArrowRight,
    faCircleQuestion,
    faCircleXmark,
    faStar,
    faBug,
    faVolumeHigh,
    faVolumeXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
    faBook,
    faBookOpen,
    faArrowRight,
    faCircleQuestion,
    faCircleXmark,
    faStar,
    faBug,
    faVolumeHigh,
    faVolumeXmark
)
const pusherApiKey = process.env.VUE_APP_PUSHER_API_KEY

const emitter = mitt()
const app = createApp(App)
const rootComponent = app
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .use(VueZoomer)
    .use(Pusher, {apiKey: pusherApiKey, cluster: 'eu'})
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
app.provide("$pusher", app.config.globalProperties.$pusher)
app.provide('emitter', emitter)
app.provide('$app', rootComponent)

router.app = rootComponent
app.config.globalProperties.emitter = emitter
