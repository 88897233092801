<template>
    <div class="listener" id="alert-event-listener"></div>
</template>

<script>
import { ref, computed, defineComponent, onMounted } from '@vue/runtime-core'
import { useVueSweetAlert2 } from '@/lib/useVueSweetAlert2'
import { useChangePage } from '@/lib/useChangePage'
import { useStore } from 'vuex'
import { useApi } from '@/lib/useApi'

export default defineComponent({
    setup() {
        const $swal = useVueSweetAlert2()
        const store = useStore()
        const isAlertCurrentlyShown = ref(false)
        const alertQueue = ref([])

        const eventSource = computed(() => {
            return new EventSource(
                `https://${process.env.VUE_APP_URL}/backend/api/game/sse/alerts.php?uuid=${store.state.game.currentGame.uuid}`,
                // `https://${window.location.hostname}/backend/api/game/sse/alerts.php?uuid=${store.state.game.currentGame.uuid}`,
                { withCredentials: false }
            )
        })

        eventSource.value.onmessage = (event) => {
            let json = JSON.parse(event.data)
            if (!json.alert) return
            alertQueue.value.push(json.alert)
            alertLogic()
            return false
        }

        async function sweetAlert(alertObject) {
            const confirmationObject = await $swal.fire(alertObject)
            useApi('alertSeen', {
                uuid: store.state.game.currentGame.uuid,
                logId: alertObject.log_id,
            })

            //console.log(confirmationObject)

            if (confirmationObject.isConfirmed) {
                switch (alertObject.action_type) {
                    case 'PlayerSolvedRiddle':
                        return useChangePage({ method: 'nextRiddle' })
                        break
                    case 'GamePaused':
                        break
                    case 'GameResumed':
                        break
                    case 'GameFinished':
                        break
                    case 'PlayerUsedHint':
                        //console.log(`Hint: ${alertObject.target_id} aufklappen`)
                        break
                }
            }
            isAlertCurrentlyShown.value = false
        }

        const alertLogic = () => {
            if (isAlertCurrentlyShown.value) return
            if (alertQueue.value.length == 0) return
            if (window.location.pathname === '/game/map') return
            let alert = alertQueue.value.shift()
            isAlertCurrentlyShown.value = true
            sweetAlert(alert)
        }

        onMounted(() => {
            setInterval(alertLogic, 1000)
        })
    },
})
</script>

<style></style>
