const shop = {
    state: () => ({
        amount: 1,
        itemId: 0,
        coupon: '',
        gift: false,
        accessCode: '',
        payPalId: '',
        isCouponValid: false,
        regularPrice: 14.99,
        discountedPrice: 14.99,
        email: ''
    }),
    getters: {},
    mutations: {
        updateCoupon(state, payload) {
            state.coupon = payload.coupon
        },
        updateIsCouponValid(state, payload) {
            state.isCouponValid = payload.isCouponValid
        },
        updateRegularPrice(state, payload) {
            state.regularPrice = payload.regularPrice
        },
        updateDiscountedPrice(state, payload) {
            state.discountedPrice = payload.discountedPrice
        },
        updateAccessCode(state, payload) {
            state.accessCode = payload.accessCode
        },
        updatePayPalId(state, payload) {
            state.payPalId = payload.payPalId
        },
        updateEmail(state, payload) {
            state.email = payload.email
        },
        updateGift(state, payload) {
            state.gift = payload.gift
        }
    },
    actions: {
        async checkIsCouponValid({ commit, state }) {
            /* prettier-ignore */
            let isCouponValid = await (await fetch(`https://${process.env.VUE_APP_URL}/backend/api/coupon/check_coupon_valid.php/?coupon=${state.coupon}`)).json()
            commit({
                type: 'updateIsCouponValid',
                isCouponValid: isCouponValid,
            })
        },
        async updateRegularPrice({ commit, state }) {
            /* prettier-ignore */
            let regularPrice = await (await fetch(`https://${process.env.VUE_APP_URL}/backend/api/item/price.php/?item_id=${state.itemId}`)).json()
            commit({
                type: 'updateRegularPrice',
                regularPrice: regularPrice,
            })
        },
        async updateDiscountedPrice({ commit, state }) {
            /* prettier-ignore */
            let discountedPrice = await (await fetch(`https://${process.env.VUE_APP_URL}/backend/api/item/discounted_price.php/?item_id=${state.itemId}&coupon=${state.coupon}`)).json()
            commit({
                type: 'updateDiscountedPrice',
                discountedPrice: discountedPrice,
            })
        },
    },
}

export default shop
