import i18n from '../i18n'

const shopRoutes = [
    {
        path: '/',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (home.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "home" */ '@/views/Shop/Home.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import(/* webpackChunkName: "login" */ '@/views/Shop/Login.vue'),
    },
    {
        path: '/agb',
        name: 'Agb',
        component: () =>
            import(/* webpackChunkName: "login" */ '@/views/Shop/AGB.vue'),
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: () =>
            import(
                /* webpackChunkName: "login" */ '@/views/Shop/Impressum.vue'
            ),
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: () =>
            import(
                /* webpackChunkName: "login" */ '@/views/Shop/Datenschutz.vue'
            ),
    },
    {
        path: '/bewerten',
        name: 'Bewerten',
        component: () =>
            import(/* webpackChunkName: "login" */ '@/views/Shop/Bewerten.vue'),
    },
    {
        path: '/bewertung',
        name: 'Bewertungen',
        component: () =>
            import(/* webpackChunkName: "login" */ '@/views/Shop/Bewertung.vue'),
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: () =>
            import(/* webpackChunkName: "shop" */ '@/views/Shop/Kontakt.vue'),
    },
    {
        path: '/zugangscode',
        name: 'Zugangscode',
        component: () =>
            import(/* webpackChunkName: "shop" */ '@/views/Shop/ZugangsCode.vue'),
    },
    // Language Routes
    {
        path: '/:lang',
        name: 'Main',
        // route level code-splitting
        // this generates a separate chunk (buy.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "" */ '@/views/Shop/Empty.vue'),
        beforeEnter(to, from, next) {
            let lang = to.params.lang
            if (i18n.locale !== lang) {
                i18n.locale = lang
            }
            return next()
        },
        children: [
            {
                path: '',
                name: 'HomeLang',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "shop" */ '@/views/Shop/Home.vue'
                    ),
            },
            {
                path: 'login',
                name: 'LoginLang',
                component: () =>
                    import(
                        /* webpackChunkName: "shop" */ '@/views/Shop/Login.vue'
                    ),
            },
            {
                path: 'agb',
                name: 'agbLang',
                component: () =>
                    import(
                        /* webpackChunkName: "shop" */ '@/views/Shop/AGB.vue'
                    ),
            },
            {
                path: 'impressum',
                name: 'impressumLang',
                component: () =>
                    import(
                        /* webpackChunkName: "shop" */ '@/views/Shop/Impressum.vue'
                    ),
            },
            {
                path: 'datenschutz',
                name: 'datenschutzLang',
                component: () =>
                    import(
                        /* webpackChunkName: "shop" */ '@/views/Shop/Datenschutz.vue'
                    ),
            },
            {
                path: 'bewerten',
                name: 'bewertenLang',
                component: () =>
                    import(
                        /* webpackChunkName: "shop" */ '@/views/Shop/Bewerten.vue'
                    ),
            },
            {
                path: 'kontakt',
                name: 'kontaktLang',
                component: () =>
                    import(
                        /* webpackChunkName: "shop" */ '@/views/Shop/Kontakt.vue'
                    ),
            },
            {
                path: 'zugangscode',
                name: 'ZugangscodeLang',
                component: () =>
                    import(/* webpackChunkName: "shop" */ '@/views/Shop/ZugangsCode.vue'),
            },
        ],
    },
]
export default shopRoutes
