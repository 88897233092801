export default {
  "Utilities": {
    "Alerts": {
      "GamePaused": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "GameResumed": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "GameFinished": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "PlayerSolvedRiddle": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "TeamChangedPage": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "Hint": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "ConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "DenyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
    },
    "Toasts": {
      "AnswerRightButNotFinal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "AnswerWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "TeamChangedPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "EmailNichtKorrekt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
    },
    "Riddles": {
      "DescriptionReadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
    },
    "Fragments": {
      "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
    }
  },
  "Components": {
    "Game": {
      "Kirche": {
        
      },
      "Schloss": {
        "KugelGif": {
          
        },
        "SchiebeTafel": {
          
        },
        "Soundboard": {
          
        },
        "WappenRiddle": {
          
        }
      },
      "AlertEventListener": {
        
      },
      "DisableInput": {
        
      },
      "GameEventListener": {
        
      },
      "GameFooter": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "GameHeader": {
        
      },
      "Hint": {
        "HintValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "ShowHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "ConfirmUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "HintWrapper": {
        
      },
      "Inventory": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "InventoryButton": {
        
      },
      "InventoryItem": {
        
      },
      "JigsawPopup": {
        
      },
      "PlayerVerifier": {
        
      },
      "Popup": {
        
      },
      "PopupContainer": {
        
      },
      "RiddleDescriptionSound": {
        
      },
      "RiddleDescriptionVideo": {
        
      },
      "RiddleInput": {
        "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "SwitchButton": {
        
      },
      "SyncButton": {
        "IsSynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "NotSynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "Timer": {
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "ToastEventListener": {
        
      }
    },
    "Shop": {
      "InfoSection": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "FeatureTitles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          
        ],
        "FeatureTexts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          
        ]
      },
      "Item": {
        "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "ItemPrice": {
        
      },
      "Nav": {
        "HomeLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "LoginLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "ProductSection": {
        "Titles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          
        ],
        "Texts": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          
        ]
      },
      "ShopFooter": {
        "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "ToS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "ShopHeader": {
        
      },
      "StartSlider": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      }
    },
    "DebugBar": {
      
    },
    "LanguageChanger": {
      
    }
  },
  "Views": {
    "Game": {
      "Kirche": {
        "Altar": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "AltarRueckseite": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Aposteldenkmal": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Steintafel": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Taufbecken": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Wegweiser": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        }
      },
      "Obelisk": {
        "AlexanderSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Obelisk": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "OttoSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "SpohieSchulte": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        }
      },
      "Schloss": {
        "Adler": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Eingang": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Eiskeller": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Fragment": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Galerie": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Kaminzimmer": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "Treppe": {
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        }
      },
      "Finished": {
        
      },
      "Karte": {
        
      },
      "Tutorial": {
        
      }
    },
    "Shop": {
      "ABG": {
        
      },
      "Datenschutz": {
        
      },
      "Empty": {
        
      },
      "Home": {
        
      },
      "Impressum": {
        
      },
      "Login": {
        "Titles": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          
        ],
        "Labels": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          
        ],
        "Buttons": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
          
        ]
      }
    }
  }
}