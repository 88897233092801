<template>
    <div class="shoving-riddle-container">
        <div class="popup-close-button" @click="closePopup">X</div>
        <div class="shoving-riddle" :style="{ backgroundImage: 'url(' + backgroundImg + ')' }">
            <div class="shoving-riddle-input">
                <button @click="leftMove(0)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[0]]" alt="" />
                <button @click="rightMove(0)">→</button>
            </div>
            <div class="shoving-riddle-input">
                <button @click="leftMove(1)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[1]]" alt="" />
                <button @click="rightMove(1)">→</button>
            </div>
            <div class="shoving-riddle-input">
                <button @click="leftMove(2)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[2]]" alt="" />
                <button @click="rightMove(2)">→</button>
            </div>
            <div class="shoving-riddle-input">
                <button @click="leftMove(3)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[3]]" alt="" />
                <button @click="rightMove(3)">→</button>
            </div>
            <div class="shoving-riddle-input">
                <button @click="leftMove(4)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[4]]" alt="" />
                <button @click="rightMove(4)">→</button>
            </div>
            <div class="shoving-riddle-input">
                <button @click="leftMove(5)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[5]]" alt="" />
                <button @click="rightMove(5)">→</button>
            </div>
            <div class="shoving-riddle-input">
                <button @click="leftMove(6)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[6]]" alt="" />
                <button @click="rightMove(6)">→</button>
            </div>
            <div class="shoving-riddle-input">
                <button @click="leftMove(7)">←</button>
                <img class="shoving-riddle-img" :src="imgs[inputs[7]]" alt="" />
                <button @click="rightMove(7)">→</button>
            </div>
        </div>
    </div>

</template>

<script>
import { useEmitter } from '@/lib/useEmitter'
import { toRef, ref } from 'vue'
import * as gottErde from '@/assets/schloss/eingang/figuren/Erde.png'
import * as gottJupiter from '@/assets/schloss/eingang/figuren/Jupiter.png'
import * as gottMars from '@/assets/schloss/eingang/figuren/Mars.png'
import * as gottMerkur from '@/assets/schloss/eingang/figuren/Merkur.png'
import * as gottNeptun from '@/assets/schloss/eingang/figuren/Neptun.png'
import * as gottSaturn from '@/assets/schloss/eingang/figuren/Saturn.png'
import * as gottUranus from '@/assets/schloss/eingang/figuren/Uranus.png'
import * as gottVenus from '@/assets/schloss/eingang/figuren/Venus.png'
import * as backgroundImg from '@/assets/schloss/eingang/holzkasten.png'
import { watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default {
    name: 'SchiebeTafel',
    props: {
        imgSrc: {
            required: true,
            type: String
        }
    },
    setup(props) {
        const store = useStore()
        const emitter = useEmitter()

        const leftMove = (inputIndex) => {
            if (inputs.value[inputIndex] == 0) {
                inputs.value[inputIndex] = 7
            } else {
                inputs.value[inputIndex] -= 1
            }
        }
        const rightMove = (inputIndex) => {
            if (inputs.value[inputIndex] == 7) {
                inputs.value[inputIndex] = 0
            } else {
                inputs.value[inputIndex] += 1
            }
        }
        const closePopup = () => {
            // emitter.emit('removePopup')
            store.commit({
                type: 'removePopup',
                imgSrc: props.imgSrc,
            })
        }

        const inputs = ref([0, 0, 0, 0, 0, 0, 0, 0])
        const imgs = ref([
            gottErde, //3
            gottJupiter, //5
            gottMars, //4
            gottMerkur, //1
            gottNeptun, //8
            gottSaturn, //6
            gottUranus, //7
            gottVenus, //2
        ])

        watchEffect(() => {
            //console.log([...inputs.value])
            //console.log([3, 7, 0, 2, 1, 5, 6, 4])
            /*console.log(
                JSON.stringify([...inputs.value]) ===
                    JSON.stringify([3, 7, 0, 2, 1, 5, 6, 4])
            )*/
            if (
                JSON.stringify([...inputs.value]) ===
                JSON.stringify([3, 7, 0, 2, 1, 5, 6, 4])
            ) {
                emitter.emit('doneSchiebetafel')
                store.commit({
                    type: 'removePopups'
                })
            }
        })
        return { closePopup, imgs, inputs, backgroundImg, leftMove, rightMove }
    },
}
</script>

<style scoped>
.shoving-riddle {
    z-index: 3;
    position: relative;
    /* border: 1px solid #333; */
    /* border-radius: 19px; */
    padding: 15px;
    width: 100%;
    max-width: 800px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 250px;
}

.shoving-riddle-img {
    height: 50px;
    width: 50px;
    margin-left: 50px;
    margin-right: 50px;
}

.shoving-riddle-input button {
    background: #63563D;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 5px;
}
.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
.shoving-riddle-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2;
    height: calc(100vh);
    width: 100%;
}
</style>
