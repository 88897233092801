<template>
    <div class="popup">
        <div class="popup-close-button" @click="closePopup">X</div>
        <div class="riddle">
            <img class="riddle-img" :src="kamin" />
            <svg
                class="riddle-svg"
                viewBox="0 0 1536 2049"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    class="btn-svg"
                    @click="playAudio(morseF); clickedSound('F')"
                    d="M598.5 81L775 85V275.5H600.5L598.5 81Z"
                />
                <path
                    class="btn-svg"
                    @click="playAudio(morseE); clickedSound('E')"
                    d="M609 471L774 473.5L773 642H613L609 471Z"
                />
                <path
                    class="btn-svg"
                    @click="playAudio(morseU); clickedSound('U')"
                    d="M875 284.5L1039.5 287.5L1032.5 458.5L873 461L875 284.5Z"
                />
                <path
                    class="btn-svg"
                    @click="playAudio(morseR); clickedSound('R')"
                    d="M868.5 655.5L1026.5 657L1020 816L867.5 817L868.5 655.5Z"
                />
            </svg>
        </div>
    </div>
</template>

<script>
import * as morseF from '@/assets/schloss/kaminzimmer/F.mp3'
import * as morseE from '@/assets/schloss/kaminzimmer/E.mp3'
import * as morseU from '@/assets/schloss/kaminzimmer/U.mp3'
import * as morseR from '@/assets/schloss/kaminzimmer/R.mp3'
import * as kamin from '@/assets/schloss/kaminzimmer/soundboard.jpeg'
import { ref } from 'vue'
import { useEmitter } from '@/lib/useEmitter'
import { useStore } from 'vuex'
export default {
    setup() {
        const playAudio = (sound) => new Audio(sound).play()
        const currentCode = ref([])
        const emitter = useEmitter()
        const store = useStore()

        const clickedSound = (letter) => {
            if(letter === 'F') currentCode.value = ['F']
            else currentCode.value.push(letter)

            if(currentCode.value.toString() === ['F', 'E', 'U', 'E', 'R'].toString()) emitter.emit(`soundboardDone`)
        }

        const closePopup = () => {
            store.commit({
                type: 'removePopup',
                imgSrc: 'Soundboard',
            })
        }

        return {
            closePopup,
            playAudio,
            clickedSound,
            currentCode,
            morseF,
            morseE,
            morseU,
            morseR,
            kamin,
        }
    },
}
</script>

<style scoped>

/* TEST */
.riddle .riddle-img,
.riddle .riddle-svg {
    aspect-ratio: 3/4;
    max-width: 100%;
}

.popup {
    position: relative;
    background: rgba(0,0,0,.9);
    z-index: 2;
    height: 100vh;
    width: 100%;
}

.riddle{
    position: relative;
    height: 100vh;
    margin: 0 auto 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: none;
}

.popup-close-button {
    position: absolute;
    cursor: pointer;
    width: 20px;
    text-align: center;
    top: 10px;
    right: 10px;
    z-index: 3;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 7px;
    padding: 7px;
}
</style>
