<template>
    <div class="locale-changer">
        <ul v-show="ShowLang">
            <li v-for="(lang, index) in langs" :key="index">
                <router-link
                    :to="{
                        name: $router.currentRoute.name,
                    }"
                    @click.prevent="setLocale(lang.code)" @click="(ShowLang=!ShowLang)"
                    ><img :src="lang.img" :alt="lang.text"></router-link
                >
            </li>
        </ul>
        <div @click="(ShowLang = !ShowLang)" class="currentLang">
            <img v-show="!ShowLang" :src="language[this.$i18n.locale]">
            <img v-show="ShowLang" :src="flagx">
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { defineComponent } from '@vue/runtime-core'
import DeutschImg from '@/assets/de.svg.png'
import EnglischImg from '@/assets/en.svg.png'
import empty from '@/assets/logo.png'
import flagx from '@/assets/flag-x.png'

export default defineComponent({
    name: 'LanguageChanger',
    setup() {
        const langs = ref([
            {
                code: 'de',
                text: 'Deutsch',
                img: DeutschImg,
            },
            {
                code: 'en',
                text: 'English',
                img: EnglischImg,
            },
        ])

        const language = {
            en: EnglischImg,
            de: DeutschImg
        }
        
        const ShowLang = ref(0)

        function setLocale(locale) {
            this.$i18n.locale = locale
        }
        return { langs, setLocale, ShowLang, empty, language, flagx }
    },
})
</script>

<style scoped>
.locale-changer{
    position: fixed;
    bottom: 75px;
    left: 50px;
    z-index: 100;
}
.locale-changer ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.locale-changer img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
}
.currentLang{
    cursor: pointer;
}
</style>
