<template>
    <div class="sync-button">
        <input
            type="checkbox"
            id="sync-input"
            name="sync-input"
            :checked="store.state.game.currentGame.isSyncedWithTeam"
            disabled
        />
        <button @click="toggleSync">{{ $t(buttonText) }}</button>
    </div>
</template>

<script>
import { computed, defineComponent } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default defineComponent({
    setup() {
        const store = useStore()
        const buttonText = computed(() => {
            return store.state.game.currentGame.isSyncedWithTeam
                ? `Components.Game.SyncButton.IsSynced`
                : `Components.Game.SyncButton.NotSynced`
        })
        const toggleSync = () => {
            store.state.game.currentGame.isSyncedWithTeam
                ? store.commit({
                      type: 'updateIsSyncedWithTeam',
                      isSyncedWithTeam: 0,
                  })
                : store.commit({
                      type: 'updateIsSyncedWithTeam',
                      isSyncedWithTeam: 1,
                  })
        }
        return { store, buttonText, toggleSync }
    },
})
</script>

<style>
.sync-button {
    flex-basis: 30%;
}
@media screen and (max-width: 650px) {
    .sync-button {
        flex-basis: auto;
    }
}
</style>
