<template>
    <div id="nav">
        <router-link :to="`/${$i18n.locale}/`">{{
            $t(`Components.Shop.Nav.HomeLink`)
        }}</router-link>
        |
        <router-link :to="`/${$i18n.locale}/login`">{{
            $t(`Components.Shop.Nav.LoginLink`)
        }}</router-link>
    </div>
</template>
<style>
#nav a{
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>