<template>
    <div class="listener" id="game-event-listener"></div>
</template>

<script>
import { computed, defineComponent, watchEffect } from '@vue/runtime-core'
import { useForceChangePage } from '@/lib/useChangePage'
import { useStore } from 'vuex'
import { useApi } from '@/lib/useApi'

export default defineComponent({
    setup() {
        const store = useStore()

        const eventSource = computed(() => {
            return new EventSource(
                `https://${process.env.VUE_APP_URL}/backend/api/game/sse/game.php?uuid=${store.state.game.currentGame.uuid}`,
                { withCredentials: false }
            )
        })

        watchEffect(() => {
            if (store.getters.allRiddlesSolved()) {
                eventSource.value.close()
            }
        })

        eventSource.value.onmessage = async (event) => {
            let json = JSON.parse(event.data)
            if (json.keep_alive) return
            switch (json.action) {
                case 'team_page':
                    // await teamPageToast()
                    store.commit('updatePage', json.data)
                    useForceChangePage(json.data)
                    break
                case 'team_state':
                    store.commit('updateGameState', json.data)
                    break
                case 'time_added':
                    store.commit('updateTimeAdded', json.data)
                    break
            }
        }
    },
})
</script>

<style></style>
