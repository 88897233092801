import i18n from '../i18n'

const gameRoutes = [
    {
        path: '/game/tutorial',
        name: 'Tutorial',
        component: () =>
            import(/* webpackChunkName: "map" */ '@/views/Game/Tutorial.vue'),
    },
    {
        path: '/game/finished',
        name: 'Finished',
        component: () =>
            import(/* webpackChunkName: "map" */ '@/views/Game/Finished.vue'),
    },
    {
        path: '/game/map',
        name: 'Map',
        component: () =>
            import(/* webpackChunkName: "map" */ '@/views/Game/Karte.vue'),
    },
    // Routes Kirche
    {
        path: '/game/kirche/einleitung',
        name: 'Einleitung',
        component: () =>
            import(
                /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Einleitung.vue'
            ),
    },
    {
        path: '/game/kirche/wegweiser',
        name: 'Wegweiser',
        component: () =>
            import(
                /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Wegweiser.vue'
            ),
    },
    {
        path: '/game/kirche/steintafel',
        name: 'Steintafel',
        component: () =>
            import(
                /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Steintafel.vue'
            ),
    },
    {
        path: '/game/kirche/aposteldenkmal',
        name: 'Aposteldenkmal',
        component: () =>
            import(
                /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Aposteldenkmal.vue'
            ),
    },
    {
        path: '/game/kirche/taufbecken',
        name: 'Taufbecken',
        component: () =>
            import(
                /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Taufbecken.vue'
            ),
    },
    {
        path: '/game/kirche/altar',
        name: 'Altar',
        component: () =>
            import(
                /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Altar.vue'
            ),
    },
    {
        path: '/game/kirche/altar_rueckseite',
        name: 'AltarRückseite',
        component: () =>
            import(
                /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/AltarRueckseite.vue'
            ),
    },
    {
        path: '/game/kirche/fragment',
        name: 'KircheFragment',
        component: () =>
            import(
                /*webpackChunkName: "kirche" */ '@/views/Game/Kirche/Fragment.vue'
            ),
    },
    // Route Schloß
    {
        path: '/game/schloss/einleitung',
        name: 'Schloss Einleitung',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Einleitung.vue'
            ),
    },
    {
        path: '/game/schloss/adler',
        name: 'Adler',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Adler.vue'
            ),
    },
    {
        path: '/game/schloss/eiskeller',
        name: 'Eiskeller',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Eiskeller.vue'
            ),
    },
    {
        path: '/game/schloss/eingang',
        name: 'Eingang',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Eingang.vue'
            ),
    },
    {
        path: '/game/schloss/treppe',
        name: 'Treppe',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Treppe.vue'
            ),
    },
    {
        path: '/game/schloss/galerie',
        name: 'Galerie',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Galerie.vue'
            ),
    },
    {
        path: '/game/schloss/kaminzimmer',
        name: 'Schloss6',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Kaminzimmer.vue'
            ),
    },
    {
        path: '/game/schloss/fragment',
        name: 'SchlossFragment',
        component: () =>
            import(
                /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Fragment.vue'
            ),
    },
    // Obelisk
    {
        path: '/game/obelisk/einleitung',
        name: 'Obelisk Einleitung',
        component: () =>
            import(
                /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/Einleitung.vue'
            ),
    },
    {
        path: '/game/obelisk/alexander_schulte',
        name: 'AlexanderSchulte',
        component: () =>
            import(
                /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/AlexanderSchulte.vue'
            ),
    },
    {
        path: '/game/obelisk/otto_schulte',
        name: 'OttoSchulte',
        component: () =>
            import(
                /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/OttoSchulte.vue'
            ),
    },
    {
        path: '/game/obelisk/sophie_schulte',
        name: 'SophieSchulte',
        component: () =>
            import(
                /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/SophieSchulte.vue'
            ),
    },
    {
        path: '/game/obelisk/obelisk',
        name: 'Obelisk',
        component: () =>
            import(
                /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/Obelisk.vue'
            ),
    },
    {
        path: '/game/obelisk/fragment',
        name: 'Fragment',
        component: () =>
            import(
                /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/Fragment.vue'
            ),
    },
    {
        path: '/game/credits',
        name: 'Credits',
        component: () =>
            import(/*webpackChunkName: "credits" */ '@/views/Game/Credits.vue'),
    },

    // Language Routes
    {
        path: '/:lang',
        name: 'GameLang',
        // route level code-splitting
        // this generates a separate chunk (buy.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "" */ '@/views/Shop/Empty.vue'),
        beforeEnter(to, from, next) {
            let lang = to.params.lang
            if (i18n.locale !== lang) {
                i18n.locale = lang
            }
            return next()
        },
        children: [
            {
                path: 'game/tutorial',
                name: 'TutorialLang',
                component: () =>
                    import(/* webpackChunkName: "map" */ '@/views/Game/Tutorial.vue'),
            },
            {
                path: 'game/finished',
                name: 'FinishedLang',
                component: () =>
                    import(/* webpackChunkName: "map" */ '@/views/Game/Finished.vue'),
            },
            {
                path: 'game/map',
                name: 'MapLang',
                component: () =>
                    import(/* webpackChunkName: "map" */ '@/views/Game/Karte.vue'),
            },
            // Routes Kirche
            {
                path: 'game/kirche/einleitung',
                name: 'Einleitung2',
                component: () =>
                    import(
                        /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Einleitung.vue'
                    ),
            },
            {
                path: 'game/kirche/wegweiser',
                name: 'Wegweiser2',
                component: () =>
                    import(
                        /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Wegweiser.vue'
                    ),
            },
            {
                path: 'game/kirche/steintafel',
                name: 'Steintafel2',
                component: () =>
                    import(
                        /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Steintafel.vue'
                    ),
            },
            {
                path: 'game/kirche/aposteldenkmal',
                name: 'Aposteldenkmal2',
                component: () =>
                    import(
                        /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Aposteldenkmal.vue'
                    ),
            },
            {
                path: 'game/kirche/taufbecken',
                name: 'Taufbecken2',
                component: () =>
                    import(
                        /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Taufbecken.vue'
                    ),
            },
            {
                path: 'game/kirche/altar',
                name: 'Altar2',
                component: () =>
                    import(
                        /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/Altar.vue'
                    ),
            },
            {
                path: 'game/kirche/altar_rueckseite',
                name: 'AltarRückseite2',
                component: () =>
                    import(
                        /* webpackChunkName: "kirche" */ '@/views/Game/Kirche/AltarRueckseite.vue'
                    ),
            },
            {
                path: 'game/kirche/fragment',
                name: 'KircheFragment2',
                component: () =>
                    import(
                        /*webpackChunkName: "kirche" */ '@/views/Game/Kirche/Fragment.vue'
                    ),
            },
            // Route Schloß
            {
                path: 'game/schloss/einleitung',
                name: 'Schloss Einleitung2',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Einleitung.vue'
                    ),
            },
            {
                path: 'game/schloss/adler',
                name: 'Adler2',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Adler.vue'
                    ),
            },
            {
                path: 'game/schloss/eiskeller',
                name: 'Eiskeller2',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Eiskeller.vue'
                    ),
            },
            {
                path: 'game/schloss/eingang',
                name: 'Eingang2',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Eingang.vue'
                    ),
            },
            {
                path: 'game/schloss/treppe',
                name: 'Treppe2',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Treppe.vue'
                    ),
            },
            {
                path: 'game/schloss/galerie',
                name: 'Galerie2',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Galerie.vue'
                    ),
            },
            {
                path: 'game/schloss/kaminzimmer',
                name: 'Schloss62',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Kaminzimmer.vue'
                    ),
            },
            {
                path: 'game/schloss/fragment',
                name: 'SchlossFragment2',
                component: () =>
                    import(
                        /*webpackChunkName: "schloss" */ '@/views/Game/Schloss/Fragment.vue'
                    ),
            },
            // Obelisk
            {
                path: 'game/obelisk/einleitung',
                name: 'Obelisk Einleitung2',
                component: () =>
                    import(
                        /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/Einleitung.vue'
                    ),
            },
            {
                path: 'game/obelisk/alexander_schulte',
                name: 'AlexanderSchulte2',
                component: () =>
                    import(
                        /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/AlexanderSchulte.vue'
                    ),
            },
            {
                path: 'game/obelisk/otto_schulte',
                name: 'OttoSchulte2',
                component: () =>
                    import(
                        /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/OttoSchulte.vue'
                    ),
            },
            {
                path: 'game/obelisk/sophie_schulte',
                name: 'SophieSchulte2',
                component: () =>
                    import(
                        /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/SophieSchulte.vue'
                    ),
            },
            {
                path: 'game/obelisk/obelisk',
                name: 'Obelisk2',
                component: () =>
                    import(
                        /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/Obelisk.vue'
                    ),
            },
            {
                path: 'game/obelisk/fragment',
                name: 'Fragment2',
                component: () =>
                    import(
                        /*webpackChunkName: "obelisk" */ '@/views/Game/Obelisk/Fragment.vue'
                    ),
            },
            {
                path: 'game/credits',
                name: 'Credits2',
                component: () =>
                    import(/*webpackChunkName: "credits" */ '@/views/Game/Credits.vue'),
            },
        ],
    },
]

export default gameRoutes
