const general = {
    state: () => ({
        view: 'shop',
    }),
    mutations: {
        updateView(state, view) {
            state.view = view
        },
    },
    getters: {},
}

export default general
