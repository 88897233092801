<template>
    <button class="hint-btn" @click="closeHints" v-show="wrapperIsShown">
        <font-awesome-icon icon="circle-xmark" />
    </button>
    <button class="hint-btn" @click="openHints" v-show="!wrapperIsShown">
        <font-awesome-icon icon="circle-question" />
    </button>
    <div :class="wrapperClassList">
        <Hint v-for="hint in hints" :key="hint.id" :hint="hint" />
    </div>
    <div
        class="debugcheck"
        v-if="parseInt(store.state.game.currentGame.uuid) < 10"
    >
        <button
            class="hint-btn2"
            @click="debug = !debug"
            v-show="!wrapperIsShown"
        >
            <font-awesome-icon icon="bug" />
        </button>
        <div class="debug-bar" v-show="debug">
            <DebugBar />
        </div>
    </div>
</template>

<script>
import Hint from '@/components/Game/Hint'
import { defineComponent, onMounted, ref, watchEffect } from '@vue/runtime-core'
import { mapGetters, useStore } from 'vuex'
import { useApi } from '@/lib/useApi'
import DebugBar from '@/components/DebugBar'
import { useEmitter } from '../../lib/useEmitter'

export default defineComponent({
    components: {
        Hint,
        DebugBar,
    },
    setup() {
        const store = useStore()
        const hints = ref()
        const emitter = useEmitter()
        const isLoaded = mapGetters(['pageSetupDone'])
        const delay = (ms) => new Promise((res) => setTimeout(res, ms))
        const wrapperClassList = ref(`hint-wrapper hidden `)
        const wrapperIsShown = ref(false)
        const openHints = () => {
            //console.log(`Open`)
            wrapperIsShown.value = true
            wrapperClassList.value = `hint-wrapper`
        }
        const closeHints = () => {
            //console.log(`Close`)
            wrapperIsShown.value = false
            wrapperClassList.value = `hint-wrapper hidden`
        }

        emitter.on('fetchHints', () => fetchHints())

        const fetchHints = async () => {
            //console.log(`fetching Hints for `, window.location.pathname)
            if (window.location.pathname == `/game/map`) return

            while (window.location.pathname == `undefined`) {
                await delay(1000)
            }

            let fetchedHints = await useApi('getHints', {
                currentPage: window.location.pathname,
            })
            hints.value = fetchedHints
        }

        onMounted(() => {
            fetchHints()
        })

        store.subscribe((mutation, state) => {
            if (mutation.type !== 'updatePage') return
            fetchHints()
        })

        watchEffect(() => {
            if (!isLoaded) return
            fetchHints()
        })

        return {
            hints,
            wrapperIsShown,
            wrapperClassList,
            openHints,
            closeHints,
            store,
        }
    },
    data() {
        return {
            debug: false,
        }
    },
})
</script>

<style>
.hint-wrapper,
.hint-btn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
}
.hint-wrapper {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-bg-color);
    border: 1px solid var(--base-black);
    padding: 5px 15px;
    border-radius: 7px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    gap: 15px;
}
.hint-btn {
    border: 1px solid var(--base-black);
    border-radius: 7px;
    background: var(--base-white);
    text-align: center;
    color: var(--base-black);
    cursor: pointer;
    line-height: 40px;
    height: 40px;
    width: 40px;
    padding: 0;
}
.hidden {
    display: none;
}
.hint-btn2 {
    position: absolute;
    top: 0;
    left: 120px;
    z-index: 4;
}
.hint-btn2 {
    border: 1px solid var(--base-black);
    border-radius: 7px;
    background: var(--base-white);
    text-align: center;
    color: var(--base-black);
    cursor: pointer;
    line-height: 40px;
    height: 40px;
    width: 40px;
    padding: 0;
}
</style>
