<template>
    <div :class="dynamicStyle" v-if="view == 'game'">
        <!-- Functional Components -->
        <PlayerVerifier />
        <!--SyncButton /-->
        <!--AlertEventListener v-if="isLoaded" />
        <ToastEventListener v-if="isLoaded" />
        <GameEventListener v-if="isLoaded" /-->
        <PusherSocket :apiKey="store.state.shop.accessCode" v-if="isLoaded"/>
        <PopupContainer />
        <!-- Game Components -->
        <div class="header">
            <HintWrapper />
            <LanguageChanger />
            <GameHeader />
        </div>
        <div class="content">
            <router-view class="router-view" v-if="isLoaded" />
        </div>
        <div class="footer">
            <GameFooter />
        </div>
    </div>
    <div class="shop-view" v-if="view == 'shop'">
        <Nav />
        <LanguageChanger />
        <ShopHeader />
        <router-view class="router-view" />
        <ShopFooter />
    </div>
</template>

<script>
import { defineComponent, computed, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import LanguageChanger from '@/components/LanguageChanger'
// Game Imports
import GameHeader from '@/components/Game/GameHeader'
import GameFooter from '@/components/Game/GameFooter'
import PlayerVerifier from '@/components/Game/PlayerVerifier'
import ToastEventListener from '@/components/Game/ToastEventListener'
import AlertEventListener from '@/components/Game/AlertEventListener'
import GameEventListener from '@/components/Game/GameEventListener'
import HintWrapper from '@/components/Game/HintWrapper'
import PopupContainer from '@/components/Game/PopupContainer'
import Inventory from '@/components/Game/Inventory'
import SyncButton from '@/components/Game/SyncButton.vue'
// Shop Imports
import ShopHeader from '@/components/Shop/ShopHeader'
import ShopFooter from '@/components/Shop/ShopFooter'
import Nav from '@/components/Shop/Nav'
import PusherSocket from '@/components/PusherSocket.vue'

export default defineComponent({
    components: {
        SyncButton,
        PusherSocket,
        LanguageChanger,
        // Game Components
        GameHeader,
        GameFooter,
        PlayerVerifier,
        ToastEventListener,
        AlertEventListener,
        GameEventListener,
        HintWrapper,
        PopupContainer,
        Inventory,
        // Shop Components
        ShopHeader,
        ShopFooter,
        Nav,
    },
    setup() {
        const store = useStore()
        const view = computed({
            get() {
                return store.state.general.view
            },
            set(value) {
                store.commit('updateView', value)
            },
        })

        // Check, ob alle asynchronen Daten geladen sind.
        const isLoaded = computed(() => {
            return store.getters.initialSetupDone()
        })

        // Wenn etwas im localStorage gespeichert wurde, damit der Code nicht erneut eingegeben werden muss.
        onMounted(() => {
            store.commit({
                type: `updateAccessCode`,
                accessCode: localStorage.getItem('accessCode'),
            })
            store.commit({
                type: `updatePayPalId`,
                payPalId: localStorage.getItem('payPalId'),
            })
            // console.log(process.env.VUE_APP_URL)
        })

        const dynamicStyle = computed(() => {
            if (store.state.game.currentGame.descriptionRead) {
                return `game-view read-desc`
            } else {
                return `game-view not-read-desc`
            }
        })

        return { view, store, isLoaded, dynamicStyle }
    },
})
</script>

<style>
.handy-hinweis {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    height: 100vh;
    padding: 15px;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
#nav {
    padding: 30px;
}
#nav a {
    font-weight: bold;
    color: #2c3e50;
}
#nav a.router-link-exact-active {
    color: #42b983;
}
.hidden {
    display: none;
}
body {
    margin: 0;
}
#shop-view {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}
</style>
