<template>
    <div :class="classes">
        <span class="hint-value">
            {{ timeValue }} {{ $t(`Components.Game.Hint.HintValue`) }}
        </span>
        <button @click="showHint()">
            {{ $t(`Components.Game.Hint.ShowHint.${ hinttype }`) }}
        </button>
        <span
            v-if="isShown"
            :id="`hint-${props.hint.id}`"
            class="hint-description"
        >
            {{ props.hint.hint }}
        </span>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useApi } from '@/lib/useApi'

export default defineComponent({
    props: {
        hint: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useStore()
        const timeValue = computed(() => {
            let minutes = Math.floor(props.hint.time_value / 60)
            let seconds = props.hint.time_value - minutes * 60

            if (seconds === 0) seconds = `${seconds}0`
            if (seconds > 0 && seconds < 10) seconds = `0${seconds}`

            return `${minutes}:${seconds}`
        })
        const hinttype = props.hint.type
        const isShown = ref(false)
        const isFree = ref(false)

        const classes = computed(() =>
            isFree.value === true ? 'free hint' : 'hint'
        )

        onMounted(async () => {
            if (await checkIfHintHasBeenUsed()) isFree.value = true
        })

        const checkIfHintHasBeenUsed = async () => {
            // return false // For Development
            let res = await useApi('wasHintUsed', {
                uuid: store.state.game.currentGame.uuid,
                hint: props.hint.id,
            })

            //console.log(res)
            return res
        }

        const useHint = async () => {
            let res = await useApi('useHint', {
                uuid: store.state.game.currentGame.uuid,
                hint: props.hint.id,
            })

            isShown.value = true
        }

        async function showHint() {
            if (await checkIfHintHasBeenUsed()) {
                isShown.value = true
            } else {
                /* prettier-ignore */
                if (!window.confirm(this.$t(`Components.Game.Hint.ConfirmUse`, {timeValue: timeValue.value}))) return
                useHint()
            }
        }

        return { timeValue, props, isShown, classes, showHint, hinttype }
    },
})
</script>

<style scoped>
.hint {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

button {
    padding: 10px 5px;
    border: none;
    cursor: pointer;
    background: var(--base-white);
    font-size: 18px;
    width: 80%;
    max-width: 250px;
    margin: auto;
    margin-top: 10px;
}
.hint-description{
    color: rgba(255, 255, 255, 0.85);
    max-width: 600px;
    margin: auto;
    margin-top: 15px;
    padding: 6px;
    border: 1px solid #fff;
    background: rgb(29, 78, 78);
}
.hint-value{
    font-size: 20px;
}

.free button{
    background: #73e369;
}
</style>
