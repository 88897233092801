<template></template>
<script>
import { defineComponent } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { useChangePage } from '@/lib/useChangePage'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const fetchUserdata = async (uuid) => {
            store.dispatch({
                type: 'loadPlayerdata',
                uuid: uuid,
            })
            useChangePage({
                method: 'page',
                path: window.location.pathname,
            })
        }

        if (!Cookies.get('uuid')) {
            router.push('/login')
        } else {
            fetchUserdata(Cookies.get('uuid'))
        }
    },
})
</script>