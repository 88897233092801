<template>
    <!-- <div class="popup-wrapper"> -->
    <div v-show="isVisible" class="popup-container">
        <KeepAlive include="SchiebeTafel Soundboard Kamin">
            <component
                v-for="(popup, index) in popups"
                :is="popup.componentType"
                :key="index"
                :imgSrc="popup.imgSrc"
                :isForced="popup.isForced"
            ></component>
        </KeepAlive>
        <Inventory />
    </div>
    <Inventory v-show="!isVisible" />
    <!-- </div> -->
</template>

<script>
import Popup from '@/components/Game/Popup'
import SchiebeTafel from '@/components/Game/Schloss/SchiebeTafel.vue'
import Soundboard from '@/components/Game/Schloss/Soundboard.vue'
import Kamin from '@/components/Game/Schloss/Kamin.vue'
import Inventory from '@/components/Game/Inventory'
import { computed, defineComponent } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default defineComponent({
    components: {
        Popup,
        Inventory,
        SchiebeTafel,
        Soundboard,
        Kamin,
    },
    setup() {
        const store = useStore()
        const popups = computed(() => store.state.game.currentGame.popups)
        const isVisible = computed(() => store.getters.isPopupVisible())
        return { store, popups, isVisible }
    },
})
</script>

<style scoped>
/* .popup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
} */
.popup-container {
    display: flex;
    z-index: 3;
}
</style>
