<template>
    <header>
        <!-- <p>{{ $t(`components.GameHeader.willkommen`) }}</p> -->
        <BackgroundMusic />
        <SwitchButton />
        <!--SyncButton /-->
        <Timer />
    </header>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import SyncButton from '@/components/Game/SyncButton'
import Timer from '@/components/Game/Timer.vue'
import SwitchButton from '@/components/Game/SwitchButton.vue'
import BackgroundMusic from '@/components/Game/BackgroundMusic.vue'

export default defineComponent({
    components: {
        SyncButton,
        Timer,
        SwitchButton,
        BackgroundMusic,
    },
})
</script>

<style scoped>
header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    gap: 25%;
    position: relative;
    z-index: 2;
}
</style>
