import { createRouter, createWebHistory } from 'vue-router'
import { updateCurrentPage } from '../lib/useChangePage'
import {useGetNextPage} from "@/hooks/useGetNextPage"
import gameRoutes from './gameRoutes'
import shopRoutes from './shopRoutes'

const routes = [...shopRoutes, ...gameRoutes]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
    const toArray = to.fullPath.split('/')

    if (toArray.includes('game')) {
        router.app.$store.commit('updateView', 'game')

        // const isRiddlePage = useIsRiddlePage()
        // If desired page is already solved, skip it.
        // Get NextPage. Check if solved if yes get next page
        //let nextPage = useGetNextPage(to.fullPath)
        //updateCurrentPage(nextPage)
        updateCurrentPage(to.fullPath)
    } else {
        router.app.$store.commit('updateView', 'shop')
    }
    next()
})

router.afterEach((to, from) => {
    router.app.emitter.emit('fetchHints')
})

export default router
