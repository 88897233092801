<template>
    <button
        class="hint-btn"
        @click="openHints"
        v-show="store.state.game.currentGame.descriptionRead"
    >
        <font-awesome-icon icon="book-open" />
    </button>
    <button
        class="hint-btn"
        @click="closeHints"
        v-if="!store.state.game.currentGame.descriptionRead"
    >
        <font-awesome-icon icon="book" />
    </button>
</template>

<script>
import { defineComponent, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default defineComponent({
    setup() {
        const store = useStore()

        const openHints = () => {
            store.commit({
                type: 'updateDescriptionRead',
                descriptionRead: false,
            })
        }
        const closeHints = () => {
            store.commit({
                type: 'updateDescriptionRead',
                descriptionRead: true,
            })
        }

        return {
            store,
            openHints,
            closeHints,
        }
    },
})
</script>

<style scoped>
.hint-wrapper,
.hint-btn {
    position: absolute;
    top: 0;
    left: 40px;
}
.hint-wrapper {
    left: 40px;
    background: var(--main-bg-color);
    border: 1px solid var(--base-black);
    padding: 5px 15px;
    border-radius: 7px;
}
.hint-btn {
    border: 1px solid var(--base-black);
    border-radius: 7px;
    background: var(--base-white);
    text-align: center;
    color: #333;
    cursor: pointer;
    line-height: 40px;
    height: 40px;
    width: 40px;
    padding: 0;
}
.hidden {
    display: none;
}
</style>
