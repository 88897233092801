import { useApi } from '@/lib/useApi'

const game = {
    state: () => ({
        currentGame: {
            /* Fetchables */
            uuid: '',
            displayName: '',
            currentPage: '',
            previousPage: '',
            gameState: {},
            isSyncedWithTeam: false,
            timePlayed: 0,
            timeAdded: 0,

            /* Frontend-Only */
            popups: [],
            descriptionRead: false,
            disableRiddleInput: false,

            /* Hybrid */
            inventory: [],
        },
        settings: {
            volume: 1.0, // Einfach Lautstärkeregler, Standart ist 100%
            voice: 'default', // Welche Stimme soll die Geschichte erzählen. Die Texte und vorgelesenden Segmente werden angepasst.
            easyMode: false, // Sollen Subtitles angezeit werden, falls es Bilder mit nicht ganz eindeutiger Schrift gibt? z.B. Alt Deutsch, oder Freimaurer-Code
            historyMode: false, // Sollen ergänzende Elemente für die Geschichte angezeigt werden z.B. Links zu Wikipedia o.ä. (interessant für Schulen?)
        },
        loadingStatus: {
            initialSetup: {
                loadedUuid: false,
                loadedDisplayName: false,
                loadedCurrentPage: false,
                loadedPreviousPage: false,
                loadedTimePlayed: false,
                loadedTimeAdded: false,
                loadedGameState: false,
            },
            pageSetup: {
                loadedCurrentPage: false,
                loadedInventory: false,
            },
        },
    }),
    mutations: {
        updateDisableRiddleInput(state, payload) {
            state.currentGame.disableRiddleInput = payload.disableRiddleInput
        },
        // loadingStatus
        updateLoadingStatus(state, payload) {
            state.loadingStatus[payload.category][payload.name] = payload.status
        },
        // gameState updates
        updateUuid(state, payload) {
            state.currentGame.uuid = payload.uuid
        },
        updateDisplayName(state, payload) {
            state.currentGame.displayName = payload.displayName
        },
        updateTimePlayed(state, payload) {
            state.currentGame.timePlayed = payload.timePlayed
        },
        updateTimeAdded(state, payload) {
            state.currentGame.timeAdded = payload | 0
        },
        updateGameState(state, payload) {
            state.currentGame.gameState = payload.gameState
        },
        updateCurrentPage(state, payload) {
            state.currentGame.currentPage = payload.currentPage
        },
        updatePreviousPage(state, payload) {
            state.currentGame.previousPage = payload.previousPage
        },
        updateDescriptionRead(state, payload) {
            state.currentGame.descriptionRead = payload.descriptionRead
        },
        updateIsSyncedWithTeam(state, payload) {
            state.currentGame.isSyncedWithTeam = payload.isSyncedWithTeam
        },
        // inventory updates
        updateInventory(state, payload) {
            let newInventory = state.currentGame.inventory.concat(
                payload.inventory
            )

            // Remove Duplicate Items from Array
            function removeDuplicates(inArray) {
                var arr = inArray.concat() // create a clone from inArray so not to change input array
                //create the first cycle of the loop starting from element 0 or n
                for (var i = 0; i < arr.length; ++i) {
                    //create the second cycle of the loop from element n+1
                    for (var j = i + 1; j < arr.length; ++j) {
                        //if the two elements are equal , then they are duplicate
                        if (arr[i].img === arr[j].img) {
                            arr.splice(j, 1) //remove the duplicated element
                        }
                    }
                }
                return arr
            }

            let filteredInventory = removeDuplicates(newInventory)

            state.currentGame.inventory = filteredInventory
        },
        resetInventory(state) {
            state.currentGame.inventory = []
        },
        // popup updates
        addPopup(state, payload) {
            state.currentGame.popups.push({
                imgSrc: payload.imgSrc,
                componentType: payload.componentType,
                isForced: payload?.isForced,
            })
        },
        removePopup(state, payload) {
            /* prettier-ignore */
            if (payload.imgSrc) state.currentGame.popups = state.currentGame.popups.filter((currentPopup) => currentPopup.imgSrc !== payload.imgSrc)
            else state.currentGame.popups.pop()
        },
        removePopups(state) {
            /* prettier-ignore */
            state.currentGame.popups = []
        },
    },
    getters: {
        // Loading Status
        initialSetupDone: (state) => () => {
            return Object.values(state.loadingStatus.initialSetup).every(
                (loadingState) => loadingState === true
            )
        },
        initialSetupMissing: (state) => () => {
            return Object.keys(state.loadingStatus.initialSetup).filter(
                /* prettier-ignore */
                (loadingState) => !state.loadingStatus.initialSetup[loadingState] ? true : false
            )
        },
        pageSetupDone: (state) => () => {
            return Object.values(state.loadingStatus.pageSetup).every(
                (loadingState) => loadingState === true
            )
        },
        pageSetupMissing: (state) => () => {
            return Object.keys(state.loadingStatus.pageSetup).filter(
                /* prettier-ignore */
                (loadingState) => !state.loadingStatus.pageSetup[loadingState] ? true : false
            )
        },
        // End Loading Status
        // Gamestatus Getters
        isPopupVisible: (state) => () => {
            return !!state.currentGame.popups.length
        },
        isRiddleSolved: (state) => (page) => {
            if(state.currentGame.gameState){
                if (Object.values(state.currentGame.gameState).length <= 0)
                    return false
                return Object.values(state.currentGame.gameState).find(
                    (riddle) => riddle.page === page
                )?.status
                    ? true
                    : false
            }return false
            // return state.currentGame.gameState.find(
            //     (riddle) => riddle.page === page
            // ).status
            //     ? true
            //     : false
        },
        getSolvedRiddleByPage: (state) => (page) => {
            return state.currentGame.gameState.find(
                (riddle) => riddle.page === page
            )
        },
        allRiddlesSolved: (state) => () => {
            // console.log(state)
            /* prettier-ignore */
            if(state.currentGame.gameState){
                if (Object.values(state.currentGame.gameState).length <= 0) return false
                return Object.values(state.currentGame.gameState).every(
                    (riddle) => riddle.status === 1
                )
            }return false
        },
        // End Gamestatus Getters
    },
    actions: {
        async loadGameData({ commit }, payload) {
            let playerData = await useApi('loadPlayerdata', {
                uuid: payload.uuid,
            })
            commit({
                type: 'updateGameState',
                gameState: playerData.game_state,
            })
        },
        async loadPlayerdata({ commit }, payload) {
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedCurrentPage',
                status: false,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'pageSetup',
                name: 'loadedCurrentPage',
                status: false,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedDisplayName',
                status: false,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedTimePlayed',
                status: false,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedTimeAdded',
                status: false,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedGameState',
                status: false,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedPreviousPage',
                status: false,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedIsSyncedWithTeam',
                status: false,
            })

            // Fetching
            let playerData = await useApi('loadPlayerdata', {
                uuid: payload.uuid,
            })

            // Uuid
            commit({
                type: 'updateUuid',
                uuid: payload.uuid,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedUuid',
                status: true,
            })

            // Display Name
            commit({
                type: 'updateDisplayName',
                displayName: playerData.display_name,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedDisplayName',
                status: true,
            })

            // GameState
            commit({
                type: 'updateGameState',
                gameState: playerData.game_state,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedGameState',
                status: true,
            })

            // TimePlayed
            commit({
                type: 'updateTimePlayed',
                timePlayed: playerData.time_played,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedTimePlayed',
                status: true,
            })

            // TimeAdded
            commit({
                type: 'updateTimeAdded',
                timeAdded: playerData.time_added,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedTimeAdded',
                status: true,
            })

            // CurrentPage
            commit({
                type: 'updateCurrentPage',
                currentPage: playerData.current_page,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedCurrentPage',
                status: true,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'pageSetup',
                name: 'loadedCurrentPage',
                status: true,
            })

            // PreviousPage
            commit({
                type: 'updatePreviousPage',
                previousPage: playerData.previous_page,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedPreviousPage',
                status: true,
            })

            // SyncedWithTeam
            commit({
                type: 'updateIsSyncedWithTeam',
                isSyncedWithTeam: playerData.is_synced_with_team,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'initialSetup',
                name: 'loadedIsSyncedWithTeam',
                status: true,
            })
        },
        async loadInventory({ commit }, payload) {
            commit({
                type: 'updateLoadingStatus',
                category: 'pageSetup',
                name: 'loadedInventory',
                status: false,
            })
            commit({
                type: 'resetInventory',
            })

            let inventory = await useApi('loadInventory', {
                uuid: payload.uuid,
            })

            commit({
                type: 'updateInventory',
                inventory: inventory,
            })
            commit({
                type: 'updateLoadingStatus',
                category: 'pageSetup',
                name: 'loadedInventory',
                status: true,
            })
        },
        async updatePage({ commit, state }, payload) {
            commit({
                type: 'updatePreviousPage',
                previousPage: state.currentGame.currentPage,
            })
            commit({
                type: 'updateCurrentPage',
                currentPage: payload.page,
            })
            commit({
                type: 'updateDescriptionRead',
                descriptionRead: false,
            })

            useApi('playerUpdateCurrentPage', {
                uuid: state.currentGame.uuid,
                page: payload.page,
            })
        },
    },
}

export default game
