<template>
    <div class="debug-bar">
        <button @click="logState">logState</button>
        <button @click="logout">logout</button>
        <button @click="clearTeamState">clearTeamState</button>
        <button @click="clearPlayerState">clearPlayerState</button>
        <button @click="logCurrentRoute">logCurrentRoute</button>
        <button @click="returnToMap">returnToMap</button>
        <button @click="checkAllRiddlesSolved">checkAllRiddlesSolved</button>
        <button @click="kaminview">KaminView</button>
        <button @click="initialSetupDone">initialSetupDone</button>
        <button @click="initialSetupMissing">initialSetupMissing</button>
        <button @click="pageSetupDone">pageSetupDone</button>
        <button @click="pageSetupMissing">pageSetupMissing</button>
        <button @click="loadPlayerdata">loadPlayerdata</button>
        <button @click="loadInventory">loadInventory</button>
        <button @click="loadInventoryAPITest">loadInventoryAPITest</button>
        <button @click="nextPage">nextPage</button>
    </div>
</template>

<script>
import { defineComponent, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@/lib/useApi'
import { useChangePage } from '@/lib/useChangePage.js'
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons'

export default defineComponent({
    setup() {
        // HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!
        // HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!
        const debug = ref(true)
        // HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!
        // HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!HIER ÄNDERN FÜR DEBUG BAR!

        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        const logState = () => {
            console.log(store.state)
        }

        const logout = () => {
            Cookies.remove('uuid')
            window.location = 'http://localhost:8080/login'
        }

        const logCurrentRoute = () => {
            console.log(route.fullPath)
        }

        /* prettier-ignore */
        const clearTeamState = () => fetch(`https://${process.env.VUE_APP_URL}/backend/api/debug/clearTeamState.php?uuid=${store.state.game.currentGame.uuid}`)

        /* prettier-ignore */
        const clearPlayerState = () => fetch(`https://${process.env.VUE_APP_URL}/backend/api/debug/clearPlayerState.php?uuid=${store.state.game.currentGame.uuid}`)

        /* prettier-ignore */
        const returnToMap = () => router.push('/game/map')

        /* prettier-ignore */
        const checkAllRiddlesSolved = () => console.log(store.getters.allRiddlesSolved())
        const kaminview = () => router.push('/game/schloss/kaminzimmer')

        const initialSetupDone = () =>
            console.log(store.getters.initialSetupDone())

        const initialSetupMissing = () =>
            console.log(store.getters.initialSetupMissing())

        const pageSetupDone = () => console.log(store.getters.pageSetupDone())

        const pageSetupMissing = () =>
            console.log(store.getters.pageSetupMissing())

        const loadPlayerdata = () => {
            store.dispatch({
                type: 'loadPlayerdata',
                uuid: `d63f993c-7cdf-4100-b66e-c177914b61d2`,
            })
        }

        const loadInventory = () => {
            store.dispatch({
                type: 'loadInventory',
                uuid: `d63f993c-7cdf-4100-b66e-c177914b61d2`,
            })
        }

        const loadInventoryAPITest = () => {
            console.log(
                useApi(`loadInventory`, {
                    uuid: `d63f993c-7cdf-4100-b66e-c177914b61d2`,
                })
            )
        }

        const nextPage = () => {
            useApi('forceSolveRiddle', {
                uuid: store.state.game.currentGame.uuid,
                currentPage: window.location.pathname,
            })
            useChangePage({ method: 'nextRiddle' })
        }

        return {
            debug,
            logState,
            logout,
            clearTeamState,
            clearPlayerState,
            logCurrentRoute,
            returnToMap,
            checkAllRiddlesSolved,
            kaminview,
            initialSetupDone,
            initialSetupMissing,
            pageSetupDone,
            pageSetupMissing,
            loadPlayerdata,
            loadInventory,
            loadInventoryAPITest,
            nextPage,
        }
    },
})
</script>

<style scoped>
.debug-bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 25%;
    margin-top: 40px;
    padding: 10px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--base-color);
}
.debug-bar button{
    margin: 8px;
    padding: 5px;
    width: calc(50% - 16px);
    font-size: 15px;
    background: #fff;
    border: 2px solid #000;
    border-radius: 5px;
}
.debug-bar button:hover{
    cursor: pointer;
    background: #f6f6f6;
}
.debug-bar button:active{
    cursor: pointer;
    background: var(--base-color);
}
</style>
