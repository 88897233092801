<template>
    <footer>
        <div class="standort">
            <p>{{ $t(`Views.Shop.Standorte.Title`) }}</p>
                <a target="_blank" href="https://mystery-house.de/bremen">Bremen</a>
                <a target="_blank" href="https://mystery-house.de/hamburg">Hamburg</a>
                <a target="_blank" href="https://mystery-house.de/flensburg">Flensburg</a>
        </div>
        <div class="footer-functions">
            <RiddleInput />
        </div>
        <p>{{ $t(`Components.Game.GameFooter.Text`) }}</p>
    </footer>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import RiddleInput from '@/components/Game/RiddleInput'
import { useStore } from 'vuex'

export default defineComponent({
    components: {
        RiddleInput,
    },
    setup() {
        const store = useStore()

        return { store }
    },
})
</script>

<style scoped>
footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* display: flex;
    justify-content: center; */
    padding-left: 10px;
    padding-right: 10px;
}
p {
    text-align: right;
}
.footer-functions {
    display: flex;
    justify-content: center;
}
.standort{
    width: 100;
    display: flex;
    flex-direction: row;
}
.standort a{
    font-size: 14px;
    color: var(--base-white);
    text-decoration: underline;
    text-align: center;
    margin: auto 10px auto 10px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
