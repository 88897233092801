import router from '@/router'
import store from '@/store'
import { useApi } from '@/lib/useApi'
import Cookies from 'js-cookie'
import { debounce } from 'lodash'

const getNextRiddlePage = (currentPath) => {
    let nextPage = useApi('getNextPage', { currentPath: currentPath })
    // fetch(
    //     `https://${process.env.VUE_APP_URL}g-sittensen.de/backend/api/game/player/getNextPage.php?current_path=${currentPath}`
    // ).then((res) => res.json())
    return nextPage
}

export const updateCurrentPage = debounce(
    (page) => {
        // useApi('playerUpdateCurrentPage', {
        //     uuid: store.state.game.currentGame.uuid,
        //     page: page,
        // })
        // fetch(
        //     `https://${process.env.VUE_APP_URL}/backend/api/game/player/updateCurrentPage.php?uuid=${store.state.game.currentGame.uuid}&next_page=${page}`
        // )
        if (store.state.game.currentGame.isSyncedWithTeam) {
            useApi('teamUpdateCurrentPage', {
                uuid: store.state.game.currentGame.uuid,
                page: page,
            })
            // fetch(
            //     `https://${process.env.VUE_APP_URL}/backend/api/game/team/updateCurrentPage.php?uuid=${store.state.game.currentGame.uuid}&next_page=${page}`
            // )
        }
        // store.commit({
        //     type: 'updatePage',
        //     page: page,
        // })
        store.dispatch({
            type: 'updatePage',
            page: page,
        })
        // store.commit('resetInventory')
        // store.dispatch({
        //     type: 'loadInventory',
        //     uuid: Cookies.get('uuid'),
        // })
        store.dispatch({
            type: 'loadGameData',
            uuid: Cookies.get('uuid')
        })
    },
    333
)

export const useForceChangePage = (page) => {
    // fetch(
    //     `https://${process.env.VUE_APP_URL}/backend/api/game/team/updateCurrentPage.php?uuid=${store.state.game.currentGame.uuid}&next_page=${page}`
    // )
    useApi('teamUpdateCurrentPage', {
        uuid: store.state.game.currentGame.uuid,
        page: page,
    })
    router.push(page)
}

export const useChangePage = async ({ method, path }) => {
    switch (method) {
        case 'nextRiddle':
            let nextPage = await getNextRiddlePage(
                router.currentRoute._value.fullPath
            )
            router.push(nextPage)
            break
        case 'page':
            router.push(path)
            break
    }
}
