<template>
    <div class="inventory" v-if="store.state.game.currentGame.descriptionRead">
        <!-- <h3 class="inventory-title">
            {{ $t('Components.Game.Inventory.Title') }}
        </h3> -->
        <div class="inventory-items">
            <InventoryItem v-for="item in items" :key="item.id" :item="item" />
        </div>
    </div>
</template>

<script>
import { computed, defineComponent } from '@vue/runtime-core'
import InventoryItem from '@/components/Game/InventoryItem'

// Items
import { useStore } from 'vuex'

export default defineComponent({
    components: {
        InventoryItem,
    },
    setup() {
        const store = useStore()
        const items = computed(() => store.state.game.currentGame.inventory)

        return { items, store }
    },
})
</script>

<style scoped>
.inventory {
    flex-basis: 10%;
    z-index: 2;
    background-color: var(--main-bg-color);
    overflow: auto;
    padding-top: 40px;
}
.inventory-title {
    text-align: center;
    color: var(--secondary-font-color);
}
.inventory-items {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 5px;

    overflow-y: auto;
}
</style>
